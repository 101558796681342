import { Injectable } from "@angular/core";
import { BehaviorSubject, filter, firstValueFrom } from "rxjs";

@Injectable({providedIn: "root"})
export class CConfirmPanelService {
    public active: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public requestedName: string = "";
    public confirmed: boolean = false;

    public async confirm(requestedName: string): Promise<boolean> {
        this.requestedName = requestedName;
        this.confirmed = false;
        this.active.next(true);
        await firstValueFrom(this.active.pipe(filter(a => !a))); // ждем закрытия
        return this.confirmed; 
    }
}
