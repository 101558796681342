import { BehaviorSubject, Observable, Subject, filter, firstValueFrom, map } from "rxjs";
import { IWsMessage } from "src/app/model/dto/wsmessage";
import { mscPause } from "../../functions/misc";

export abstract class CWsClient {
    public connected: BehaviorSubject<boolean> = new BehaviorSubject(false);
    protected abstract url: string;
    protected socket: WebSocket = null;
    protected subject: Subject<IWsMessage> = new Subject();    

    public async connect(): Promise<void> { // не всегда нужен автоматический коннект, поэтому эта функция публична, а конструктор в базовом классе не задаем явным образом 
        this.socket = new WebSocket(this.url); // can use `${ini.wsUrl}?id=123` or pass any other data by GET parameter!
        this.socket.onopen = this.onOpen.bind(this);
        this.socket.onclose = this.onClose.bind(this);
        this.socket.onerror = this.onError.bind(this);
        this.socket.onmessage = this.onMessage.bind(this);        
    }

    public disconnect(forever: boolean = true): void {
        if (!this.socket) return;
        if (forever) this.socket.onclose = null;
        this.socket.close();
        console.log("socket disconnected");
    }

    public on<T>(event: string): Observable<T> {        
        return this.subject.asObservable().pipe( // создаем новый observable, полученный из внутреннего subject фильтрацией и извлечением данных
            filter(message => message.event === event),
            map(message => message.data),
        );
    }

    public async send(message: IWsMessage): Promise<void> {
        this.connected.value && this.socket?.send(JSON.stringify(message));
    }

    public waitForConnection(): Promise<boolean> {
        return firstValueFrom(this.connected.pipe(filter(c => c)));
    }

    //////////////////
    // utils
    //////////////////

    private async reconnect(): Promise<void> {
        console.log(`socket reconnecting...`);
        await mscPause(1000);
        this.connect();
    }

    private onOpen(): void {
        this.connected.next(true);
        console.log("socket connected");
    }

    private onClose(): void {
        this.connected.next(false);
        this.reconnect();
    }

    private onError(): void {
        console.log("socket error");
    }

    private onMessage(rawMessage: MessageEvent): void {
        const message = JSON.parse(rawMessage.data) as IWsMessage;
        message.event === "ping" ? this.send({event: "pong"}) : this.subject.next(message); // в этом проекте реализуем полноценный пинг-понг, чтобы сервер точно знал, когда клиент отвалился, в том числе в случае, когда у клиента есть подключение, но нет интернета                
    }
}
