import { Injectable } from "@angular/core";
import { CAuthService } from "../auth.service";
import { ini } from "src/app/app.ini";
import { CWsClient } from "./_ws.client";

@Injectable({providedIn: "root"})
export class CRobotBackClient extends CWsClient {
    constructor(protected authService: CAuthService) {
        super();
    }

    get user_id(): number {return this.authService?.user_id;}
    get url(): string {return `${ini.robotBackUrl}?type=user&id=${this.user_id}&tzoffset=${new Date().getTimezoneOffset()}`;} // tzoffset в принципе пока не используется, но для единообразия с операторами добавим
}
